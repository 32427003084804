export default {
	prefix: 'isd',
	iconName: 'suchen',
	niceName: 'suchen',
	icon: [
		57,
		57,
		[],
		'ff45',
		'M55.742 49.358l-10.87-10.87c7.073-10.41 5.073-24.49-4.62-32.518-9.693-8.028-23.9-7.37-32.81 1.518a24.42 24.42 0 0017.26 41.66 24.27 24.27 0 0017.27-7.15c.48-.49.94-1 1.37-1.5l10.63 10.62a2 2 0 01-2.74 2.74l-8.31-8.3a1.25 1.25 0 10-1.76 1.77l8.31 8.31a4.002 4.002 0 002.89 1.18 4.592 4.592 0 003.24-1.36 4.34 4.34 0 00.14-6.1zm-15.54-9.13c-7.386 7.405-18.974 8.561-27.676 2.761C3.823 37.19.429 26.05 4.42 16.383 8.412 6.716 18.677 1.216 28.936 3.246c10.26 2.03 17.656 11.024 17.666 21.482a21.77 21.77 0 01-6.4 15.5z'
	]
};