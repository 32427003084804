export default {
	prefix: 'isd',
	iconName: 'jetzt-anrufen',
	niceName: 'jetzt-anrufen',
	icon: [
		41,
		51,
		[],
		'ff25',
		'M31.299 50.982c-.502.006-1.001-.082-1.47-.26-6.44-2.2-12.9-8-19.2-17.31l-.39-.56c-.08-.13-.17-.25-.29-.42-6.41-9.13-9.64-17.56-9.32-24.39 0-.56.13-2.7 3-4.43 1.91-1.23 5.37-3.26 7.05-3.14a3.44 3.44 0 012.91 2.15l3.7 8.67a4.13 4.13 0 01-.58 4.65l-2.92 3.55a74.41 74.41 0 004.6 7.49l.08.12v.06l.06.1a74.86 74.86 0 005.34 7l4.35-1.45a4.14 4.14 0 014.55 1.14l6.73 6.56a3.44 3.44 0 011 3.51c-.49 1.62-3.64 4.11-5.5 5.46a6.072 6.072 0 01-3.7 1.5zm-.45-2.55s1 .38 2.68-.9c2.24-1.64 4.37-3.58 4.6-4.18a.85.85 0 01.06-.17s.07-.37-.42-.87l-6.75-6.59-.11-.12a1.62 1.62 0 00-1.85-.38l-5.15 1.7a1.25 1.25 0 01-1.34-.36 81.41 81.41 0 01-6.13-8 78.72 78.72 0 01-5.24-8.62 1.23 1.23 0 01.14-1.37l3.45-4.2a1.62 1.62 0 00.31-1.86l-.07-.14-3.72-8.71c-.28-.6-.63-.66-.7-.67a.43.43 0 01-.16 0c-.61 0-3.19 1.29-5.49 2.77-1.83 1.1-1.84 2.2-1.84 2.24a.57.57 0 010 .13c-.28 6.19 2.88 14.33 8.88 22.9.14.2.24.34.29.43.05.09.2.28.41.58 7.5 11 13.93 15 18 16.35l.16.07-.01-.03z'
	]
};