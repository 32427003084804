export default {
	prefix: 'isd',
	iconName: 'mobile-taetigkeitsnachweise',
	niceName: 'mobile-taetigkeitsnachweise',
	icon: [
		51,
		65,
		[],
		'ff38',
		'M33.037.055a4.07 4.07 0 014.07 4.06v4a1.25 1.25 0 01-2.5 0v-4a1.56 1.56 0 00-1.57-1.56H4.267a1.56 1.56 0 00-1.57 1.56v56.55a1.65 1.65 0 001.57 1.65h28.77a1.65 1.65 0 001.57-1.65v-16a1.25 1.25 0 012.5-.04v16a4.16 4.16 0 01-4.07 4.15H4.267a4.16 4.16 0 01-4.07-4.15V4.115a4.07 4.07 0 014.07-4.06zm-12.05 55.91a1.25 1.25 0 010 2.5h-4.68a1.25 1.25 0 010-2.5zm10.38-5.97a1.25 1.25 0 010 2.5H5.927a1.25 1.25 0 010-2.5zm4.47-38.8a15.001 15.001 0 019.2 3.1 1.253 1.253 0 01-1.51 2 12.64 12.64 0 00-7.69-2.59 12.71 12.71 0 1012.39 9.86 1.25 1.25 0 012.43-.57 15.21 15.21 0 11-14.82-11.8zm13.878 3.549l.134.015a1.25 1.25 0 01.738 2.056l-13.48 16.19a1.27 1.27 0 01-1 .45l-.147-.012a1.28 1.28 0 01-.853-.508l-7.35-10.17a1.25 1.25 0 012-1.47l6.4 8.92 12.51-15a1.25 1.25 0 011.182-.456z'
	]
};