export default {
	prefix: 'isd',
	iconName: 'treppenhausreinigung',
	niceName: 'treppenhausreinigung',
	icon: [
		77,
		55,
		[],
		'ff47',
		'M30.29 11.44a1.3 1.3 0 011.67 0l8 7a1.277 1.277 0 01-1.66 1.94l-7.16-6.3-18.9 16.93v1h7.13c.69 0 1.25.56 1.25 1.25v4.58h5.74a1.27 1.27 0 01.89.36c.23.24.358.558.36.89v4.58h5.73c.69 0 1.25.56 1.25 1.25v7.3h15.42V44.1a1.25 1.25 0 112.5 0v9.37c0 .69-.56 1.25-1.25 1.25h-40.3a1.25 1.25 0 01-1.21-1.35v-23c0-.354.148-.691.41-.93zM18.14 34.51h-5.88l-.02 17.75h19.87v-6.05h-5.75c-.691 0-1.25-.56-1.25-1.25v-4.58h-5.74a1.26 1.26 0 01-1.23-1.29v-4.58zm30.919-20.08l.14.01a1.26 1.26 0 011.09 1.4c-.16 1.25-.54 2.99-1.4 6.66a29 29 0 00-.56 4.93v10.12h14.69v-7.88a.53.53 0 010-.13 3.36 3.36 0 00-.06-.67h-13.2a1.25 1.25 0 110-2.5h11.82a6.31 6.31 0 00-.81-.68 8.79 8.79 0 01-.27-.17l-.203-.136c-1.648-1.113-6.527-4.798-6.527-8.784v-.9a1.25 1.25 0 112.5 0v.9c0 2.67 4.34 6 5.86 7a7.16 7.16 0 013.36 6.09v9.09c0 .69-.56 1.25-1.25 1.25H47.08c-.69 0-1.25-.56-1.25-1.25V27.43a31.52 31.52 0 01.62-5.47c.84-3.61 1.21-5.3 1.36-6.44a1.24 1.24 0 011.39-1.08zM31.029 2.04l10.65 9.15a1.26 1.26 0 01.13 1.77 1.25 1.25 0 01-1.76.13l-9-7.71L16.21 18.3V9.22h-3.8l.05 12.38-9.48 8.3a1.25 1.25 0 11-1.65-1.88l8.63-7.56V6.72h8.75v6.08L31.03 2.04zm28.792 8.457a1.25 1.25 0 012.138.493 7.3 7.3 0 002.51 3.58 1.25 1.25 0 01-.76 2.25 1.18 1.18 0 01-.75-.26 9.81 9.81 0 01-3.38-4.81 1.25 1.25 0 01.242-1.253zm6.758-8.357a1.25 1.25 0 011.26 1.24v4.24c0 .69-.56 1.25-1.25 1.25h-4.73a5.91 5.91 0 00-4.61 3.61 1.25 1.25 0 01-1.14.74h-10a1.24 1.24 0 01-1.15-.77L43.8 9.62a1.27 1.27 0 01.26-1.34l5.57-5.75c.233-.25.559-.39.9-.39zm7.94 5.32a1.69 1.69 0 110 3.38 1.69 1.69 0 010-3.38zM61.96 4.65H51.07l-4.63 4.79.52 1.29h8.36a8.36 8.36 0 016.45-4.35h.19V4.65zm8.69.95a1.69 1.69 0 110 3.38 1.69 1.69 0 010-3.38zm3.87-1.86a1.69 1.69 0 11.26 3.37 1.69 1.69 0 01-.26-3.37zm-9.19.9h-.84v1.74h.84V4.64zm5.32-2.77a1.69 1.69 0 110 3.38 1.69 1.69 0 010-3.38zM73.878.13a1.69 1.69 0 111.302 3.12A1.69 1.69 0 0173.878.13z'
	]
};