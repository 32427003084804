export default {
	prefix: 'isd',
	iconName: 'initiativbewerbung',
	niceName: 'initiativbewerbung',
	icon: [
		79,
		50,
		[],
		'ff23',
		'M63.257.614a5.58 5.58 0 015.57 5.57 1.25 1.25 0 01-2.46 0 3.08 3.08 0 00-3.07-3h-50.47a3.07 3.07 0 00-3.08 3v35.37h21.83c.361 0 .705.157.94.43l.66.77h9.7l.66-.77a1.28 1.28 0 011-.43h21.79v-7.37a1.25 1.25 0 012.5 0v7.33h5.71c.364 0 .71.157.95.43a1.3 1.3 0 01.227 1.118l-.027.108c-.267 1.054-1.884 6.524-6.61 6.524h-62.1c-4.627 0-6.281-5.245-6.601-6.453l-.045-.18a1.27 1.27 0 01.237-1.118c.244-.299.613-.469 1-.459h5.68v-35.3a5.57 5.57 0 015.58-5.57zm-32.26 43.36H3.307c.6 1.38 1.75 3.21 3.7 3.21h62.1c1.96 0 3.11-1.79 3.72-3.21h-27.72l-.66.76a1.24 1.24 0 01-.95.44h-10.84a1.26 1.26 0 01-1-.44l-.66-.76zm16.83-37.74a3.33 3.33 0 013.33 3.33v25.84a3.33 3.33 0 01-3.33 3.33h-18.35a3.33 3.33 0 01-3.33-3.33v-21.91a1.25 1.25 0 01.49-1l7.83-6c.217-.17.485-.26.76-.26zm21.46 3.51a1.22 1.22 0 011.54.23l1.71 1.9c.59-.34 1.19-.69 1.8-1a3.26 3.26 0 013.49.66l.22.26a3.21 3.21 0 01.69 3.39 41.82 41.82 0 01-16.41 17.4h-.12l-4.47 2.28a1.15 1.15 0 01-1.21-.07l-1.18 1.18a1.28 1.28 0 01-.89.36 1.25 1.25 0 01-.88-2.13l1.16-1.17a1.26 1.26 0 01-.14-1l.66-2.1a1.25 1.25 0 011.57-.81c.622.214.966.88.78 1.51l-.43 1.38.21.2 2.22-1.14-2.38-2.38a1.23 1.23 0 01-.2-1.51 41.55 41.55 0 0113.29-14l-.66-.75a44 44 0 00-6.38 5.19 1.23 1.23 0 01-1.76 0 1.25 1.25 0 010-1.77 46.12 46.12 0 017.69-6.11zm-21.46-1.01h-11.35v6.59c0 .69-.56 1.25-1.25 1.25h-3.25a1.25 1.25 0 110-2.5h2v-4l-5.33 4.11v21.22a.84.84 0 00.83.83h18.34a.84.84 0 00.83-.83V9.564a.84.84 0 00-.82-.83zm-2.47 21.77a1.25 1.25 0 010 2.5h-13.42a1.25 1.25 0 110-2.5zm30.134-17.332l-.114.012a39 39 0 00-15.68 14.47l2.23 2.23a39.17 39.17 0 008.83-7.35l-2.59-2.58a1.252 1.252 0 011.77-1.77l2.43 2.4a39.59 39.59 0 004.12-6.39 1 1 0 00-.2-.58l-.27-.27a.8.8 0 00-.64-.16zM45.357 25.094a1.25 1.25 0 010 2.5h-13.42a1.25 1.25 0 110-2.5zm0-5.42a1.25 1.25 0 010 2.5h-13.42a1.25 1.25 0 110-2.5z'
	]
};