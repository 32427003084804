export default {
	prefix: 'isd',
	iconName: 'meldungen',
	niceName: 'meldungen',
	icon: [
		61,
		58,
		[],
		'ff35',
		'M52.21 8.64a28.42 28.42 0 01-20.092 48.52 28.35 28.35 0 01-15.08-4.33l-14.84 2.92a1.24 1.24 0 01-1.19-.41 1.27 1.27 0 01-.24-1.23l5.21-15a1.25 1.25 0 112.36.82l-4.49 13 13.19-2.61a1.28 1.28 0 01.93.18 25.91 25.91 0 10-11.77-21.75 26.25 26.25 0 00.32 4.06 1.26 1.26 0 01-1 1.43 1.24 1.24 0 01-1.42-1 28.81 28.81 0 01-.4-4.49A28.42 28.42 0 0152.21 8.64zM29.118 19.9c.69 0 1.25.56 1.25 1.25l-.001 8.532c-.015 1.612-.342 5.768-7.19 10.568a1.21 1.21 0 01-.75.23 1.25 1.25 0 01-.72-2.27c5.92-4.152 6.124-7.418 6.13-8.628V22.4h-9.56v6.09h5.76a1.23 1.23 0 011 .5c.237.31.315.714.21 1.09a12.78 12.78 0 01-6 7 1.25 1.25 0 11-1.24-2.17 11.45 11.45 0 004.11-3.92h-5.09a1.25 1.25 0 01-1.25-1.24v-8.6c0-.69.56-1.25 1.25-1.25h12.09zm17.28 0c.69 0 1.25.56 1.25 1.25l-.001 8.532c-.015 1.612-.341 5.768-7.18 10.568a1.21 1.21 0 01-.72.23 1.29 1.29 0 01-1-.53 1.25 1.25 0 01.31-1.74c5.848-4.15 6.053-7.414 6.06-8.626V22.4h-9.52v6.09h5.76c.394 0 .765.185 1 .5.233.312.31.713.21 1.09a12.78 12.78 0 01-6 7 1.252 1.252 0 11-1.25-2.17 11.41 11.41 0 004.1-3.87h-5.07a1.25 1.25 0 01-1.23-1.29v-8.6c0-.69.56-1.25 1.25-1.25h12.03z'
	]
};