export default {
	prefix: 'isd',
	iconName: 'kontakt-standorte',
	niceName: 'kontakt-standorte',
	icon: [
		58,
		57,
		[],
		'ff2b',
		'M28.62.16c15.69 0 28.41 12.72 28.41 28.41-.022 15.681-12.729 28.388-28.41 28.41C12.93 56.98.21 44.26.21 28.57S12.93.16 28.62.16zm0 2.5c-14.31 0-25.91 11.6-25.91 25.91s11.6 25.91 25.91 25.91 25.91-11.6 25.91-25.91C54.513 14.267 42.923 2.677 28.62 2.66zm6.315 9.852A12.63 12.63 0 0141.25 23.45c0 11.92-11.32 22.12-11.8 22.55l-.83.74-.83-.74c-.48-.43-11.8-10.63-11.8-22.55a12.63 12.63 0 0118.945-10.938zm-6.315.808c-5.592.006-10.124 4.538-10.13 10.13 0 9 7.57 17.33 10.13 19.89 2.56-2.56 10.13-10.88 10.13-19.89-.006-5.592-4.538-10.124-10.13-10.13zm0 2.09a8.08 8.08 0 018.08 8.08 8.1 8.1 0 01-7.829 8.076l-.251.004a8.08 8.08 0 110-16.16zm0 2.46a5.58 5.58 0 100 11.16 5.58 5.58 0 000-11.16z'
	]
};