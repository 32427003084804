export default {
	prefix: 'isd',
	iconName: 'jobsuche',
	niceName: 'jobsuche',
	icon: [
		57,
		58,
		[],
		'ff27',
		'M7.453 8.03c8.91-8.89 23.117-9.547 32.81-1.52 9.693 8.028 11.693 22.11 4.62 32.52l10.87 10.87a4.34 4.34 0 01-.21 6.12 4.541 4.541 0 01-3 1.34h-.18a4.001 4.001 0 01-2.88-1.18l-8.31-8.31a1.25 1.25 0 111.76-1.77l8.31 8.3a1.56 1.56 0 001.2.44 2 2 0 001.37-.61 1.86 1.86 0 00.17-2.57l-10.63-10.62c-.43.5-.89 1.01-1.37 1.5a24.27 24.27 0 01-17.27 7.15l-.416-.005A24.42 24.42 0 017.453 8.03zm21.494-4.243c-10.26-2.03-20.524 3.47-24.515 13.137-3.992 9.667-.598 20.807 8.105 26.606 8.702 5.8 20.29 4.644 27.676-2.76a21.77 21.77 0 006.4-15.5c-.01-10.459-7.407-19.454-17.666-21.483zM11.873 24.839c.696 0 1.26.564 1.26 1.26v7.48h23.16V26.1a1.25 1.25 0 012.5 0v8.75a1.24 1.24 0 01-1.25 1.25h-25.66a1.24 1.24 0 01-1.27-1.27V26.1c0-.696.564-1.26 1.26-1.26zm15.68-14.11a2.83 2.83 0 012.82 2.83v1.15h7.17a1.25 1.25 0 011.23 1.25v3.27c0 5.75-6.33 7.75-12.25 7.75h-3.66c-5.92 0-12.25-2.04-12.25-7.75v-3.27a1.25 1.25 0 011.27-1.25h7.17v-1.15a2.83 2.83 0 012.82-2.83zm8.74 6.5h-23.16v2c0 3.25 3.65 5.25 9.75 5.25h3.65c6.11 0 9.75-1.96 9.75-5.25l.01-2zm-9.68 3.37a1.25 1.25 0 110 2.5h-3.84a1.25 1.25 0 110-2.5zm.93-7.37h-5.68a.32.32 0 00-.32.33l.01 1.15h6.31v-1.15a.32.32 0 00-.32-.33z'
	]
};