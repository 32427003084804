export default {
	prefix: 'isd',
	iconName: 'winterdienst',
	niceName: 'winterdienst',
	icon: [
		63,
		64,
		[],
		'ff50',
		'M51.626 11.393a3.32 3.32 0 015.027 3.026v5h2.89a1.25 1.25 0 110 2.5h-2.89v7.85h2.89a1.25 1.25 0 110 2.5h-2.89v7.84h2.89a1.25 1.25 0 110 2.5h-2.89v2.24a9.53 9.53 0 11-6.63 0v-30.43a3.32 3.32 0 011.603-3.026zM30.643.799c.69 0 1.25.56 1.25 1.25v9.73l8-8a1.245 1.245 0 011.76 1.76l-9.78 9.8v14.45l13.58-7.44a1.23 1.23 0 011.69.49 1.25 1.25 0 01-.49 1.7l-13.71 7.51 13.66 7.51a1.25 1.25 0 01-.6 2.35 1.17 1.17 0 01-.6-.16l-13.53-7.44v14.45l9.76 9.77a1.24 1.24 0 010 1.76 1.23 1.23 0 01-1.76 0l-8-8v9.76a1.25 1.25 0 11-2.5 0v-10.3l-8.58 8.57a1.245 1.245 0 01-1.76-1.76l10.38-10.39v-14.2l-12.8 7-4 13.72a1.24 1.24 0 01-1.2.91 1.33 1.33 0 01-.34 0 1.25 1.25 0 01-.86-1.55l3.26-11.3-8.62 4.74a1.2 1.2 0 01-.6.16 1.27 1.27 0 01-1.1-.65 1.25 1.25 0 01.5-1.7l8.63-4.74-11-3.4a1.25 1.25 0 01-.82-1.57 1.24 1.24 0 011.56-.82l13.31 4.11 12.42-6.83-12.42-6.83-13.31 4.11c-.119.04-.244.06-.37.06a1.25 1.25 0 01-.38-2.45l11-3.4-8.62-4.74a1.251 1.251 0 111.21-2.19l8.62 4.74-3.23-11.33a1.25 1.25 0 112.4-.69l3.92 13.72 12.8 7v-14.17l-10.32-10.34a1.245 1.245 0 011.76-1.76l8.58 8.57v-10.3c0-.69.56-1.25 1.25-1.25zm22.82 12.84a.82.82 0 00-.81.82v31.31a1.25 1.25 0 01-1 1.21 7 7 0 001.68 13.87l.13-.05a7 7 0 001.77-13.82 1.25 1.25 0 01-.95-1.21v-31.31a.83.83 0 00-.82-.82z'
	]
};