export default {
	prefix: 'isd',
	iconName: 'mitarbeiterbegleitung',
	niceName: 'mitarbeiterbegleitung',
	icon: [
		58,
		64,
		[],
		'ff36',
		'M49.74 7.485c3.01 1.24 4.43 4.69 3.19 7.69-1.24 3.01-4.69 4.43-7.69 3.19-3-1.24-4.43-4.69-3.19-7.69 1.24-3 4.69-4.43 7.69-3.19zm-2.24 1.94c-1.94 0-3.51 1.57-3.51 3.51 0 1.94 1.57 3.51 3.51 3.51 1.94 0 3.51-1.57 3.51-3.51 0-1.94-1.57-3.51-3.51-3.51zm-34.34-1.94c3.01 1.24 4.43 4.69 3.19 7.69-1.24 3.01-4.69 4.43-7.69 3.19-3-1.24-4.43-4.69-3.19-7.69 1.24-3 4.69-4.43 7.69-3.19zm-2.24 1.94c-1.94 0-3.51 1.57-3.51 3.51 0 1.94 1.57 3.51 3.51 3.51 1.94 0 3.51-1.57 3.51-3.51 0-1.94-1.57-3.51-3.51-3.51zm23.2 6.36c2.79.05 5.11 2.15 5.44 4.92l1.58 15.73c.26 2.5-1.87 4.39-4.07 5.4l-.48 19.81c0 .73-.6 1.31-1.33 1.31h-.04c-.74-.03-1.31-.65-1.28-1.39v-.01l.5-20.69c.01-.55.37-1.04.89-1.22 1.62-.59 3.29-1.83 3.21-2.98l-1.65-15.69a2.884 2.884 0 00-2.77-2.51h-9.31a2.876 2.876 0 00-2.77 2.51l-1.59 15.73c-.11 1.15 1.55 2.39 3.21 2.98.52.18.87.67.89 1.22l.5 20.69c.01.74-.57 1.35-1.31 1.37l-.06-.04c-.72 0-1.31-.56-1.34-1.27l-.48-19.81c-2.19-1.07-4.28-2.9-4.07-5.4l1.58-15.73a5.578 5.578 0 015.44-4.92h9.31v-.01zM31.99.505a6.621 6.621 0 013.59 8.65 6.621 6.621 0 01-8.65 3.59 6.621 6.621 0 01-3.59-8.65 6.621 6.621 0 018.65-3.59zm-2.53 2.19c-2.18 0-3.95 1.77-3.95 3.95s1.77 3.95 3.95 3.95 3.95-1.77 3.95-3.95-1.77-3.95-3.95-3.95zM5.15 42.065c-1.25-.58-2.34-1.52-2.25-2.4l1.42-13.98c.16-1.25 1.2-2.2 2.46-2.24h7.77c.66 0 1.19-.53 1.19-1.19 0-.66-.53-1.19-1.19-1.19H6.78a4.945 4.945 0 00-4.83 4.38L.54 39.425c-.17 2 1.31 3.52 3.03 4.5.37.21.59.61.6 1.04l.41 16.87c.03.64.55 1.14 1.19 1.13l.06.04a1.2 1.2 0 001.16-1.22l-.43-17.61c-.02-.92-.58-1.73-1.41-2.11zm51.32-16.63a4.962 4.962 0 00-4.83-4.38h-8.13c-.66 0-1.19.53-1.19 1.19 0 .66.53 1.19 1.19 1.19h8.13c1.26.04 2.3.99 2.46 2.24l1.46 13.95c.06.88-1.04 1.82-2.26 2.4-.83.39-1.38 1.19-1.4 2.11l-.43 17.61v.01c-.03.66.49 1.21 1.14 1.23h.04c.65 0 1.17-.52 1.18-1.16l.41-16.9c.01-.42.23-.8.6-1 1.73-.95 3.24-2.51 3.03-4.51l-1.41-13.98h.01z'
	]
};