export default {
	prefix: 'isd',
	iconName: 'gesellschaftliches-engagement',
	niceName: 'gesellschaftliches-engagement',
	icon: [
		71,
		65,
		[],
		'ff19',
		'M70.168 37.555a5.88 5.88 0 01-2.73 7.83l-25.22 13.52c-.092.05-.19.09-.29.12l-1.29.33h-.27l-23.34.63a15.78 15.78 0 00-6 4.55 1.23 1.23 0 01-.92.46 1.24 1.24 0 01-.94-2.07c3.379-3.828 6.332-5.03 7.088-5.291l.09-.03.092-.029a1.57 1.57 0 01.3-.05l23.39-.63 1-.26 25.13-13.45a3.39 3.39 0 001.7-4.45c-.77-1.43-2.77-1.8-4.54-.85l-12.18 6.23a1.252 1.252 0 11-1.14-2.23l12.15-6.21c3-1.61 6.48-.79 7.92 1.88zm-45.98 1.26a51.36 51.36 0 008.22.91h9.23c3.45 0 6.25 2.52 6.25 5.61 0 3.09-2.8 5.62-6.25 5.62h-12.81a1.25 1.25 0 110-2.5h12.81c2.07 0 3.75-1.4 3.75-3.12 0-1.72-1.75-3.13-3.75-3.13h-9.27a52.64 52.64 0 01-8.87-1c-.11 0-5.69-1.7-10.31 1.35l-10.55 9.75a1.24 1.24 0 01-.89.33 1.25 1.25 0 01-.85-2.17l10.67-9.82.15-.12c5.65-3.81 12.19-1.82 12.47-1.71zm28.34-35.13a10.59 10.59 0 013.11 7.66 11 11 0 01-2.98 7.413l-.24.247-15.73 15.67a1.21 1.21 0 01-.88.37 1.21 1.21 0 01-.88-.37l-15.7-15.67a10.93 10.93 0 01-3.22-7.66A10.54 10.54 0 0126.638.635h.11a10.93 10.93 0 017.63 3.18l1.43 1.43 1.44-1.43a10.92 10.92 0 017.65-3.21 10.35 10.35 0 017.63 3.08zm-7.59-.55a8.43 8.43 0 00-5.92 2.49l-2.38 2.31a1.25 1.25 0 01-1.76 0l-2.24-2.31a8.39 8.39 0 00-5.91-2.49h-.09a8 8 0 00-8.1 8.18 8.39 8.39 0 002.49 5.92l14.78 14.79 14.83-14.79a8.48 8.48 0 002.49-5.92 8.07 8.07 0 00-2.36-5.83 7.93 7.93 0 00-5.83-2.35zm-18.812 3.67a1.25 1.25 0 01-.438 2.15 2.33 2.33 0 00-.86.54 2.43 2.43 0 00-.69 1.77 2.8 2.8 0 00.83 2 1.27 1.27 0 010 1.77 1.28 1.28 0 01-.85.32 1.24 1.24 0 01-.88-.36 5.3 5.3 0 01-1.6-3.71 4.81 4.81 0 013.23-4.69 1.25 1.25 0 011.258.21z'
	]
};