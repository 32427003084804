export default {
	prefix: 'isd',
	iconName: 'versicherungen',
	niceName: 'versicherungen',
	icon: [
		55,
		69,
		[],
		'ff4d',
		'M27.797.459c.447.067.824.371.983.795a13.85 13.85 0 004.61 5.07c3.23 2.4 9.37 5.22 19.83 5.22.69 0 1.25.56 1.25 1.25v27.81c.15 1.36.63 14.72-26.32 27.46a1.235 1.235 0 01-1.06 0C.14 55.324.62 41.964.77 40.604v-27.81c0-.69.56-1.25 1.25-1.25 11.39 0 17.69-3.3 21-6.07a1.25 1.25 0 011.6 1.91c-3.49 2.94-10 6.41-21.35 6.64v26.8c0 .05-1.08 12.57 24.35 24.72 13.27-6.34 19.19-12.72 21.83-17 2.249-3.634 2.53-6.359 2.543-7.351v-.142a2.334 2.334 0 00-.013-.267c-.01-.03 0-.1 0-.15v-26.6C32.128 13.621 27.02 3.514 26.502 2.29l-.027-.065a1.003 1.003 0 01-.015-.04A1.25 1.25 0 0127.797.458zm-.177 17.305a16.36 16.36 0 0110 3.38 1.253 1.253 0 11-1.51 2 14.06 14.06 0 105.56 11.18 14.5 14.5 0 00-.35-3.16 1.25 1.25 0 112.43-.56 16.56 16.56 0 01-16.13 20.27c-8.984-.22-16.153-7.568-16.153-16.555s7.169-16.334 16.153-16.555zm15.915 4.195c.53.445.6 1.235.155 1.765l-14.84 17.74a1.24 1.24 0 01-.96.45h-.05a1.24 1.24 0 01-1-.52l-8-11.15a1.25 1.25 0 012-1.46l7.11 9.85 13.82-16.52c.445-.53 1.235-.6 1.765-.155z'
	]
};