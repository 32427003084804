export default {
	prefix: 'isd',
	iconName: 'ihre-moeglichkeiten',
	niceName: 'ihre-moeglichkeiten',
	icon: [
		75,
		62,
		[],
		'ff21',
		'M42.477 14.967a8 8 0 017.92 7.96v10.72c0 4.47-1.77 7.25-4.9 7.84v18.65a1.25 1.25 0 11-2.5 0v-19.48a.78.78 0 010-.6v-15.13a1.25 1.25 0 012.5 0v14c1.11-.38 2.4-1.57 2.4-5.28v-10.72a5.47 5.47 0 00-5.46-5.46h-9.72a5.47 5.47 0 00-5.46 5.46v10.76c0 3.71 1.29 4.9 2.4 5.28v-14a1.25 1.25 0 012.5 0v15.1a.73.73 0 010 .58v19.49a1.25 1.25 0 11-2.5 0v-18.65c-3.13-.59-4.9-3.37-4.9-7.84v-10.68a8 8 0 018-8zm-4.86 24.43c.69 0 1.25.56 1.25 1.25v19.49a1.25 1.25 0 11-2.5 0v-19.49c0-.69.56-1.25 1.25-1.25zm14.46 5.54a1.252 1.252 0 011.77 0l8 8v-3.45c0-.69.56-1.25 1.25-1.25a1.25 1.25 0 011.23 1.25v6.47c0 .69-.56 1.25-1.25 1.25h-6.46a1.25 1.25 0 110-2.5h3.46l-8-8a1.252 1.252 0 010-1.77zm-30.65-.02a1.25 1.25 0 111.76 1.77l-8.05 8h3.48a1.25 1.25 0 110 2.5h-6.44c-.69 0-1.25-.56-1.25-1.25v-6.46a1.25 1.25 0 012.5 0v3.39l8-8zM6.172 24.64c.332 0 .65.132.885.367a1.27 1.27 0 010 1.77l-2.44 2.42h11.36a1.25 1.25 0 110 2.5H4.617l2.42 2.44a1.27 1.27 0 010 1.77 1.28 1.28 0 01-.89.36 1.24 1.24 0 01-.88-.36l-4.55-4.55a1.26 1.26 0 01-.37-.89 1.22 1.22 0 01.37-.88l4.57-4.58a1.25 1.25 0 01.885-.367zm62.114.456a1.25 1.25 0 011.681-.079l4.55 4.55a1.25 1.25 0 01.02 1.77l-4.57 4.57a1.25 1.25 0 01-1.76-1.77l2.41-2.42h-11.36a1.25 1.25 0 110-2.5h11.36l-2.41-2.44a1.25 1.25 0 01.079-1.68zM63.077 3.777a1.25 1.25 0 011.23 1.21v6.48a1.25 1.25 0 11-2.5 0v-3.42l-8 8a1.21 1.21 0 01-.88.37l.02-.01a1.25 1.25 0 01-.89-2.13l8-8h-3.44a1.25 1.25 0 010-2.5zm-44.46 0a1.25 1.25 0 110 2.47h-3.43l8.02 7.97a1.25 1.25 0 01-.88 2.13 1.28 1.28 0 01-.89-.36l-8.05-8v3.47a1.25 1.25 0 11-2.5 0v-6.43a1.26 1.26 0 01.37-.88 1.22 1.22 0 01.88-.37zM35.195.64a6.31 6.31 0 114.844 11.654A6.31 6.31 0 0135.195.64zm2.422 2.027a3.81 3.81 0 100 7.62 3.81 3.81 0 000-7.62z'
	]
};