export default {
	prefix: 'isd',
	iconName: 'mail',
	niceName: 'mail',
	icon: [
		58,
		43,
		[],
		'ff31',
		'M52.9.589a4.24 4.24 0 014.23 4.19v33.59a4.23 4.23 0 01-4.23 4.22H4.35a4.23 4.23 0 01-4.24-4.18V4.819A4.24 4.24 0 014.35.589zm0 2.46H4.36a1.74 1.74 0 00-1.74 1.73v33.63a.36.36 0 000 .1l11.82-14.14a1.25 1.25 0 111.92 1.6L4.53 40.129h48.18l-11.83-14.16a1.25 1.25 0 011.92-1.6l11.82 14.14a.36.36 0 000-.1V4.779a1.74 1.74 0 00-1.72-1.73zM6.37 5.939a1.256 1.256 0 011.76-.24l20.56 15.89 20.1-15.83a1.265 1.265 0 011.55 2l-20.87 16.38c-.222.168-.492.26-.77.26a1.19 1.19 0 01-.76-.26L6.61 7.699a1.256 1.256 0 01-.24-1.76z'
	]
};