export default {
	prefix: 'isd',
	iconName: 'navigation-ausklappen',
	niceName: 'navigation-ausklappen',
	icon: [
		51,
		28,
		[],
		'ff39',
		'M25.6 27.174a1.24 1.24 0 01-.88-.36L.65 2.744A1.252 1.252 0 012.42.974l23.18 23.19L48.78.974a1.27 1.27 0 011.82 1.77l-24.12 24.07a1.24 1.24 0 01-.88.36z'
	]
};