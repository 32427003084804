export default {
	prefix: 'isd',
	iconName: 'gesundheitsmanagement',
	niceName: 'gesundheitsmanagement',
	icon: [
		62,
		67,
		[],
		'ff1b',
		'M41.27 21.378c.86-.3 6.47-2.02 11.69.77 3.73 2.03 6.38 5.87 7.73 11.43.15.77 3.21 17.76-10.24 29.97-1.56 1.81-5.91 4.33-11.56 1.81a15.79 15.79 0 00-4.49-1.08 15.68 15.68 0 00-4.58 1.1 11.41 11.41 0 01-4.64 1 9.44 9.44 0 01-6.87-2.87 31.35 31.35 0 01-5-5.67 1.25 1.25 0 112.06-1.41 29.56 29.56 0 004.63 5.27.57.57 0 01.11.11c.13.15 3.3 3.64 8.72 1.22a18.67 18.67 0 015.5-1.29 18.62 18.62 0 015.49 1.27c4.927 2.191 8.028-.475 8.646-1.082l.063-.064.051-.054.12-.11c12.49-11.21 9.57-27.44 9.57-27.6-1.16-4.75-3.33-8.08-6.46-9.75-4.58-2.45-9.71-.6-9.76-.6l-.2.06a41.53 41.53 0 01-7.24.93h-.58a40.85 40.85 0 01-7.21-.93l-.2-.06c-.05-.02-5.23-1.84-9.78.61-3.12 1.68-5.29 4.97-6.46 9.8a27.54 27.54 0 00-.31 4.05h23.68c.69 0 1.25.56 1.25 1.25v12.78c0 .69-.56 1.25-1.25 1.25H5.88a5.56 5.56 0 01-5.73-5.36v-12.79a5.56 5.56 0 015.73-5.36h3.23a14.7 14.7 0 016.57-7.83c5.22-2.77 10.83-1.07 11.69-.77a41 41 0 006.64.87h.6a38.25 38.25 0 006.66-.87zm-38.66 18.4v8.35a3.06 3.06 0 003.27 2.86h2.95v-3.86a1.25 1.25 0 012.5 0v3.86h5.59v-3.86a1.25 1.25 0 012.5 0v3.86H25v-3.86a1.25 1.25 0 012.5 0v3.86h5v-10.28H5.84a6 6 0 01-3.23-.93zm3.27-7.3a2.89 2.89 0 100 5.73h1.73a27.9 27.9 0 01.33-4.58c.1-.4.21-.77.32-1.15zm40.653-4.61a1.25 1.25 0 011.277-.17c3.34 1.25 5.59 4.17 6.68 8.69a1.25 1.25 0 01-.92 1.51 1 1 0 01-.3 0 1.26 1.26 0 01-1.21-1c-.89-3.66-2.61-6-5.12-6.86a1.25 1.25 0 01-.407-2.17zM49.08.788a1.24 1.24 0 011.44 1.49c-.07.32-1.73 7.9-4.35 11.2-3.22 4.09-9.37 4.41-11.22 4.41h-.54a1.26 1.26 0 01-1.1-.83l-.142-.336c-2.616-6.063-6.378-7.664-6.378-7.664l-.02-.01a1.253 1.253 0 01.92-2.33c.16.06 3.15 1.3 5.91 5.53.48-2.62 1.54-5.88 3.93-7.62 3.21-2.33 11.21-3.78 11.55-3.84zm-1.45 2.87c-2.74.6-6.83 1.71-8.61 3-2.41 1.73-3.11 6.13-3.25 8.72 2.07-.1 6.27-.67 8.46-3.43 1.54-1.94 2.77-5.93 3.4-8.29z'
	]
};