export default {
	prefix: 'isd',
	iconName: 'duales-studium',
	niceName: 'duales-studium',
	icon: [
		57,
		66,
		[],
		'ff13',
		'M39.226 38.994l.138.013c5.02.84 9.68 2.14 10.17 2.27.69.16 5.42 1.52 6.42 9.34l.95 13.58a1.26 1.26 0 01-1.15 1.34h-.1a1.24 1.24 0 01-1.24-1.16l-1-13.5c-.85-6.3-4.34-7.13-4.48-7.16-.14-.03-4.51-1.26-9.28-2.11l-10 13.41a1.25 1.25 0 01-2 0l-10.05-13.39-.304.054-.604.113c-2.204.418-4.272.898-5.789 1.275l-.4.1a86.822 86.822 0 00-2.083.548h-.11c-.13.03-3.62.86-4.48 7.23l-1 13.43a1.25 1.25 0 11-2.49-.18l1-13.51c1.06-7.88 5.74-9.25 6.43-9.41.49-.13 5.17-1.43 10.17-2.27.46-.069.92.119 1.2.49l9.51 12.69 9.51-12.69c.28-.371.74-.559 1.2-.49zm7.328 11.243a1.25 1.25 0 110 2.5h-7.25a1.25 1.25 0 110-2.5zM28.304.687a1.25 1.25 0 01.69 0l20 5.74c.54.148.912.64.91 1.2v12.82a1.25 1.25 0 01-2.5 0v-11l-5 1.64v5.98a1.25 1.25 0 01-.42.94c-.4.354-.817.688-1.25 1 0 1.22-.07 2.59-.23 4.28-.82 9.01-6 15.8-12 15.8s-11.15-6.8-12-15.8a1.25 1.25 0 112.48-.24c.73 7.54 4.85 13.54 9.56 13.54 4.71 0 8.81-5.82 9.55-13.54.09-.96.19-1.82.19-2.6a22.69 22.69 0 01-9.63 2.1c-5.18 0-10.19-1.69-13.41-4.52a1.25 1.25 0 01-.42-.94v-2.45a1.25 1.25 0 112.5 0v1.82a18.57 18.57 0 0011.33 3.54 18.57 18.57 0 0011.33-3.54v-4.59l-10.94 3.57a1.16 1.16 0 01-.78 0l-20.11-6.59a1.25 1.25 0 01.05-2.39zm.35 2.5l-15.85 4.55 15.85 5.2 15.94-5.2-15.94-4.55z'
	]
};