export default {
	prefix: 'isd',
	iconName: 'ihre-vorteile',
	niceName: 'ihre-vorteile',
	icon: [
		62,
		61,
		[],
		'ff2c',
		'M12.617 39.108c.69 0 1.25.56 1.25 1.25v19a1.25 1.25 0 01-2.5 0v-19c0-.69.56-1.25 1.25-1.25zm6.5 15.09c.69 0 1.25.56 1.25 1.25v3.88a1.25 1.25 0 01-2.5 0v-3.88c0-.69.56-1.25 1.25-1.25zm-1.76-38.86a7.78 7.78 0 017.2 4.82 22.55 22.55 0 0113.94-4.82c10.784-.007 20.073 7.603 22.187 18.178 2.114 10.576-3.536 21.172-13.494 25.31-9.959 4.14-21.454.67-27.459-8.289-6.004-8.958-4.847-20.91 2.766-28.549a5.27 5.27 0 00-5.14-4.15h-9.46a5.29 5.29 0 00-5.28 5.28v10.46c0 3.6 1.27 4.72 2.27 5.09v-13.57a1.25 1.25 0 112.5 0v34.23a1.25 1.25 0 01-2.5 0v-18.13c-3.04-.59-4.77-3.32-4.77-7.65v-10.43a7.79 7.79 0 017.78-7.78zm21.14 2.5c-11.112 0-20.12 9.008-20.12 20.12 0 11.112 9.008 20.12 20.12 20.12 11.105-.016 20.103-9.015 20.12-20.12 0-11.112-9.008-20.12-20.12-20.12zm-8.55 14.26a1.25 1.25 0 011.22 1.25v14.94c0 .69-.56 1.25-1.25 1.25h-4.3a1.25 1.25 0 010-2.5h3.08v-12.44h-2.67a1.25 1.25 0 010-2.5zm20.21 2.37a1.25 1.25 0 112.46.45 3.27 3.27 0 01-1.57 2.34h.09a1.25 1.25 0 011 1.46 3.24 3.24 0 01-1.73 2.33c.581.19.935.778.83 1.38a3.21 3.21 0 01-1.6 2.26h.08a1.24 1.24 0 01.62 1.65 3.6 3.6 0 01-3.37 2.25h-13.78a1.25 1.25 0 010-2.5h13.77a1.1 1.1 0 001.09-.77 1.24 1.24 0 01.19-.26h-.96a1.25 1.25 0 010-2.5h.69a.69.69 0 00.8-.62 1.27 1.27 0 01.34-.61h-.93a1.25 1.25 0 010-2.5h.69a.71.71 0 00.81-.62 1.17 1.17 0 01.33-.62 4.4 4.4 0 01-.65 0h-.74a1.25 1.25 0 010-2.5h.74a.7.7 0 00.8-.62zm-9.26-11.8a3 3 0 012.1.54 3.38 3.38 0 011.27 2.52 17.47 17.47 0 01-.32 5.5h5a1.25 1.25 0 110 2.5h-6.75a1.27 1.27 0 01-1.06-.59 1.24 1.24 0 01-.05-1.22c1.12-2.25.71-5.93.71-5.93-.05-.56-.25-.75-.25-.75a.43.43 0 00-.31-.06c-.24.03-.69-.11-.78.38a9.76 9.76 0 01-6.84 9.23 1.29 1.29 0 01-.43.07v.01a1.25 1.25 0 01-.42-2.43 7.21 7.21 0 005.19-7 3 3 0 012.94-2.77zM10.264 1.218a6.18 6.18 0 114.726 11.42 6.18 6.18 0 01-4.726-11.42zm2.353 2.04a3.68 3.68 0 10.02 7.36 3.68 3.68 0 00-.02-7.36z'
	]
};