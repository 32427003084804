export default {
	prefix: 'isd',
	iconName: 'umweltschutz',
	niceName: 'umweltschutz',
	icon: [
		71,
		64,
		[],
		'ff4a',
		'M62.244 33.837c3-1.61 6.48-.79 7.92 1.88a5.88 5.88 0 01-2.73 7.83l-25.12 13.56c-.092.05-.19.09-.29.12l-1.33.33h-.27l-23.34.63a15.78 15.78 0 00-6 4.55 1.23 1.23 0 01-.93.42 1.24 1.24 0 01-.94-2.07c3.84-4.35 7.13-5.35 7.27-5.35a1.57 1.57 0 01.3-.05l23.39-.63 1-.26 25.08-13.45a3.39 3.39 0 001.7-4.45c-.77-1.43-2.77-1.85-4.54-.85l-12.18 6.23a1.252 1.252 0 11-1.14-2.23zm-50.53 4.87c5.65-3.81 12.19-1.82 12.47-1.73a51.36 51.36 0 008.22.89h9.23c3.45 0 6.25 2.51 6.25 5.61 0 3.1-2.8 5.62-6.25 5.62h-12.81a1.25 1.25 0 110-2.5h12.81c2 0 3.75-1.41 3.75-3.13s-1.68-3.11-3.75-3.11h-9.27a52.64 52.64 0 01-8.87-1c-.11-.04-5.69-1.7-10.26 1.35l-10.6 9.76a1.266 1.266 0 11-1.74-1.84l10.67-9.8zm20.07-36.46a32.18 32.18 0 0119.91-.9c.534.183.88.697.85 1.26a36.25 36.25 0 01-7 18.45 16.39 16.39 0 01-11.73 6c-.47.06-.94.06-1.4.06a13.11 13.11 0 01-7.53-2.14 62.23 62.23 0 00-3.88 5.6 1.24 1.24 0 01-1.72.4 1.25 1.25 0 01-.59-1.84c9.44-15.28 21.13-20.6 21.63-20.82a1.25 1.25 0 011 2.29c0 .04-7.43 3.42-15 12.33a11.44 11.44 0 007.14 1.54 14.1 14.1 0 0010-5 34.31 34.31 0 006.4-16.07 31 31 0 00-17.3 1.13c-6.29 2.31-9.74 9.01-9 13.82a1.26 1.26 0 01-1 1.43 1.24 1.24 0 01-1.42-1c-1-6.54 3.88-14.08 10.64-16.54z'
	]
};